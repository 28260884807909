import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1069.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M2838 9961 c-96 -92 -217 -241 -274 -336 -127 -212 -218 -491 -256
-785 -17 -135 -17 -473 0 -615 49 -402 136 -737 299 -1145 78 -196 218 -481
325 -663 15 -26 28 -50 28 -53 0 -20 348 -549 407 -619 5 -5 38 -48 73 -95 90
-119 230 -289 325 -395 44 -49 84 -95 90 -101 26 -30 255 -261 357 -359 122
-119 295 -273 423 -375 44 -35 82 -67 85 -70 15 -16 334 -253 385 -285 33 -21
103 -66 155 -100 86 -57 250 -157 340 -208 19 -11 127 -66 240 -122 496 -248
952 -397 1445 -471 94 -14 182 -18 410 -18 312 -1 398 9 620 68 320 86 596
249 817 485 70 74 89 101 73 101 -3 0 -52 -27 -107 -60 -178 -105 -410 -183
-663 -222 -170 -27 -552 -32 -725 -10 -612 79 -1176 278 -1805 637 -201 114
-590 367 -625 405 -3 3 -21 17 -40 30 -19 14 -42 30 -50 37 -8 7 -51 41 -95
75 -246 193 -463 388 -744 668 -202 201 -519 575 -676 800 -21 30 -65 92 -97
138 -32 45 -58 87 -58 92 0 4 -4 10 -8 12 -12 4 -124 186 -225 364 -75 133
-247 465 -247 478 0 3 -11 29 -24 58 -150 333 -281 766 -331 1093 -20 135 -45
412 -45 511 0 168 36 442 81 610 42 162 139 387 210 491 16 23 29 44 29 47 0
19 -31 -5 -122 -93z"/>
<path d="M4749 9645 c-23 -24 -53 -62 -67 -82 -14 -21 -28 -40 -31 -43 -14
-11 -70 -129 -89 -186 -35 -104 -45 -187 -39 -328 8 -163 34 -262 101 -386 57
-106 151 -220 180 -220 22 0 39 28 33 53 -4 12 -19 36 -35 52 -30 31 -97 128
-125 180 -69 129 -98 250 -96 401 0 62 5 114 10 114 5 0 9 14 9 31 0 17 4 28
10 24 6 -4 10 6 10 22 0 22 2 25 10 13 9 -13 10 -13 10 0 0 39 88 183 164 267
30 33 44 57 40 67 -3 9 -11 16 -16 16 -6 0 -8 9 -5 19 4 17 -9 31 -28 31 -2 0
-23 -20 -46 -45z m-99 -295 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z"/>
<path d="M5930 9595 c0 -16 20 -50 54 -91 63 -76 120 -187 148 -287 24 -90 33
-240 20 -337 -13 -88 -9 -103 8 -28 19 82 24 237 11 314 -26 150 -99 306 -187
402 -19 20 -34 40 -34 45 0 4 -4 7 -10 7 -5 0 -10 -11 -10 -25z"/>
<path d="M5226 9522 c-13 -12 -5 -22 19 -22 14 0 25 4 25 9 0 10 -36 21 -44
13z"/>
<path d="M4942 9438 c-41 -53 -41 -71 -1 -32 36 34 52 61 41 68 -5 3 -23 -14
-40 -36z"/>
<path d="M5795 9461 c-4 -7 5 -28 19 -49 96 -135 120 -185 152 -317 5 -19 9
-24 11 -14 6 24 -34 175 -59 223 -20 40 -58 96 -96 145 -13 17 -21 20 -27 12z"/>
<path d="M5650 9312 c0 -5 15 -29 34 -53 19 -24 43 -62 55 -84 26 -50 27 -25
1 33 -32 70 -90 137 -90 104z"/>
<path d="M5640 9040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5283 8923 c-9 -23 5 -27 38 -11 l31 15 -32 5 c-21 3 -34 0 -37 -9z"/>
<path d="M5127 8896 c-3 -8 0 -19 7 -26 10 -8 15 -7 20 6 3 8 6 20 6 25 0 15
-27 10 -33 -5z"/>
<path d="M5197 8859 c-25 -14 -21 -44 8 -57 24 -11 25 -10 25 23 0 39 -8 47
-33 34z"/>
<path d="M5272 8828 c3 -39 6 -43 31 -46 36 -4 34 -19 -4 -27 -44 -10 -35 -39
15 -45 22 -3 42 -11 44 -19 3 -10 -2 -13 -22 -9 -23 5 -29 0 -46 -40 -20 -44
-30 -193 -11 -164 6 10 22 11 59 6 48 -6 51 -8 57 -41 5 -29 9 -32 21 -23 8 7
14 23 14 35 0 30 48 41 67 17 11 -15 14 -15 23 -2 6 8 8 24 5 35 -2 11 -5 49
-6 85 -1 36 -3 57 -6 48 -6 -24 -15 -22 -36 8 -23 31 -16 54 17 54 29 0 33 12
34 96 1 75 3 74 -151 74 l-108 0 3 -42z m236 -60 c-3 -7 -10 -13 -16 -12 -7 1
-21 2 -32 3 -35 2 -21 21 16 21 24 0 34 -4 32 -12z m-34 -179 c34 -40 33 -46
-9 -51 l-35 -4 0 43 c0 24 4 43 9 43 5 0 21 -14 35 -31z m-114 -9 c0 -21 -34
-45 -51 -34 -7 4 -2 15 12 30 27 29 39 30 39 4z"/>
<path d="M5615 8850 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5010 8814 c0 -14 31 -62 53 -83 33 -30 77 -23 77 12 -1 20 -2 20
-11 5 -16 -28 -33 -22 -78 27 -22 24 -41 42 -41 39z"/>
<path d="M4790 8774 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z"/>
<path d="M4820 8712 c0 -9 75 -118 96 -139 17 -18 61 -16 68 2 5 15 -1 19 -61
40 -6 2 -29 26 -50 54 -36 47 -53 61 -53 43z"/>
<path d="M5665 8710 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10 -15
0 -24 -4 -20 -10z"/>
<path d="M5790 8581 c0 -24 25 -45 45 -37 20 7 19 26 0 26 -9 0 -18 7 -21 15
-8 21 -24 19 -24 -4z"/>
<path d="M5212 8555 c-18 -38 -16 -105 3 -105 12 0 15 14 15 65 0 36 -2 65 -3
65 -2 0 -9 -11 -15 -25z"/>
<path d="M5930 8451 c-14 -27 -3 -50 27 -57 37 -10 52 12 19 27 -19 9 -26 18
-23 31 7 24 -10 23 -23 -1z"/>
<path d="M5479 8418 c-41 -44 -52 -65 -39 -73 6 -4 17 -1 25 5 8 7 24 10 35 7
18 -5 20 0 20 44 0 27 -3 49 -6 49 -3 0 -19 -15 -35 -32z"/>
<path d="M5201 8393 c1 -17 3 -21 6 -10 2 9 9 17 14 17 5 0 9 5 9 10 0 6 -7
10 -15 10 -10 0 -15 -9 -14 -27z"/>
<path d="M5270 8301 c0 -16 6 -21 25 -21 30 0 31 -9 5 -43 -25 -31 -38 -87
-20 -87 6 0 25 14 40 30 22 24 29 42 32 83 l3 52 -42 3 c-38 3 -43 1 -43 -17z"/>
<path d="M5432 8303 c2 -10 12 -19 23 -19 11 -1 28 -2 37 -3 9 -1 19 -11 21
-23 3 -15 5 -10 6 13 1 40 -10 49 -61 49 -23 0 -29 -4 -26 -17z"/>
<path d="M5400 8210 c0 -40 4 -70 10 -70 6 0 10 13 10 28 0 16 3 32 6 35 9 9
45 -11 72 -41 l22 -25 0 45 c0 43 -1 45 -26 40 -21 -4 -33 2 -54 27 -15 17
-30 31 -34 31 -3 0 -6 -31 -6 -70z"/>
<path d="M5255 8103 c-45 -5 -55 -15 -55 -59 0 -17 -7 -38 -16 -45 -14 -11
-15 -25 -10 -79 6 -58 8 -193 3 -245 -2 -19 4 -20 105 -20 72 0 111 -4 119
-12 10 -10 16 -10 28 1 17 15 96 25 107 13 12 -11 62 -8 70 5 4 6 6 39 4 72
-1 34 -5 121 -9 195 -5 120 -8 135 -26 145 -11 6 -23 15 -26 19 -4 5 -46 8
-95 7 -49 -2 -105 0 -124 2 -19 3 -53 3 -75 1z m93 -65 c2 -27 -1 -38 -11 -38
-15 0 -50 36 -60 64 -6 15 -2 17 31 14 35 -3 37 -5 40 -40z m151 26 c-7 -9
-18 -25 -24 -37 -13 -26 -31 -32 -38 -13 -8 20 30 66 55 66 18 0 19 -2 7 -16z
m61 -111 c0 -46 -13 -61 -35 -43 -11 9 -15 9 -15 1 0 -14 -27 -15 -35 -1 -3 5
4 20 17 33 13 13 29 36 36 52 11 23 15 26 23 14 5 -7 9 -33 9 -56z m-244 -38
c-3 -9 -14 -15 -23 -13 -20 4 -28 30 -18 55 5 15 8 14 27 -4 13 -13 18 -28 14
-38z m-6 -57 c0 -17 -63 -97 -77 -98 -14 0 -17 48 -7 88 5 18 13 22 45 22 23
0 39 -5 39 -12z m254 -44 c7 -62 6 -67 -15 -59 -20 9 -89 97 -83 107 3 4 25 8
48 8 l43 0 7 -56z m-72 -71 c21 -20 38 -41 38 -45 0 -12 -88 -10 -95 2 -7 11
4 80 13 80 3 0 23 -17 44 -37z m-144 -10 c-3 -37 -4 -38 -45 -41 -24 -2 -43 1
-43 6 0 12 67 72 81 72 6 0 9 -17 7 -37z"/>
<path d="M2880 7736 c0 -13 15 -64 34 -113 l34 -88 627 -3 628 -2 -6 27 c-3
16 -8 57 -12 93 -3 36 -8 75 -11 88 l-4 22 -645 0 -645 0 0 -24z"/>
<path d="M6515 7748 c-2 -7 -9 -53 -15 -102 -6 -50 -12 -95 -13 -100 -1 -8
195 -13 643 -16 484 -4 650 -8 666 -17 12 -7 34 -27 48 -44 l26 -31 -2 -1313
c-3 -1296 -3 -1313 -23 -1335 -53 -59 42 -55 -1399 -57 -725 -1 -1321 -4
-1324 -8 -3 -3 5 -13 19 -23 13 -9 36 -26 52 -37 15 -11 95 -68 178 -127 l152
-108 1278 0 c1391 0 1300 -4 1314 53 3 12 4 717 3 1567 l-3 1545 -22 42 c-27
51 -82 96 -132 112 -58 17 -1440 16 -1446 -1z"/>
<path d="M5575 7610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5455 7500 c-10 -11 -13 -20 -7 -20 6 0 16 9 22 20 6 11 9 20 7 20
-2 0 -12 -9 -22 -20z"/>
<path d="M5401 7399 c1 -20 17 -57 18 -41 1 9 8 11 23 6 19 -6 20 -5 7 7 -36
35 -49 42 -48 28z"/>
<path d="M5255 7340 c-40 -40 -70 -62 -92 -66 -34 -6 -36 -11 -47 -99 -3 -22
-15 -100 -27 -174 -21 -130 -21 -135 -3 -148 21 -15 90 -18 99 -3 11 18 125
91 133 86 5 -3 7 -20 5 -38 -6 -50 -4 -81 6 -91 13 -13 75 0 103 22 12 10 32
21 43 24 11 2 -1 5 -27 6 -48 1 -70 21 -23 21 13 0 28 8 34 17 9 17 14 16 66
-10 48 -23 55 -30 45 -42 -11 -13 -6 -15 31 -15 24 0 61 3 83 7 l38 6 -5 46
c-3 25 -10 73 -16 106 -5 33 -17 104 -25 159 -9 54 -18 101 -20 104 -3 2 -16
6 -29 7 -14 2 -35 8 -47 15 -17 9 -24 9 -30 0 -8 -13 -108 -5 -125 11 -6 6
-17 -1 -31 -18 -20 -25 -24 -26 -48 -15 -15 7 -47 12 -71 12 -25 0 -45 4 -45
9 0 5 21 26 46 47 51 43 73 74 52 74 -8 0 -40 -27 -73 -60z m61 -120 c9 0 14
-11 14 -30 0 -37 -5 -37 -53 -5 -46 32 -49 50 -6 41 17 -3 38 -6 45 -6z m244
-1 c0 -14 -81 -88 -97 -89 -9 0 -13 15 -13 44 0 26 5 46 13 49 24 10 97 7 97
-4z m66 -114 l7 -48 -61 5 c-33 4 -66 9 -72 13 -7 4 12 27 47 60 65 59 65 59
79 -30z m-370 -30 c-1 -5 -23 -10 -47 -10 l-44 0 2 40 c5 71 11 74 53 25 22
-25 38 -49 36 -55z m12 -70 c6 -3 12 -9 12 -14 0 -12 -114 -91 -130 -91 -8 0
-10 16 -5 53 4 28 9 56 12 60 4 7 79 1 111 -8z m359 3 c16 -20 25 -86 13 -93
-12 -7 -100 64 -100 81 0 7 15 15 33 17 17 2 35 5 38 5 4 1 11 -4 16 -10z"/>
<path d="M5602 7380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5189 6766 c13 -15 100 -51 107 -43 7 7 -82 57 -102 57 -13 0 -14 -3
-5 -14z"/>
<path d="M5681 6743 c0 -10 4 -27 9 -38 7 -16 9 -13 9 18 1 20 -3 37 -9 37 -5
0 -10 -8 -9 -17z"/>
<path d="M5487 6717 c-37 -20 -37 -20 -51 -1 -16 21 -20 16 -30 -43 -7 -44 5
-42 68 9 28 23 53 43 55 45 2 2 2 5 0 6 -2 2 -21 -5 -42 -16z"/>
<path d="M5702 6655 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5570 6626 c42 -17 58 -20 45 -7 -6 5 -26 12 -45 15 l-35 6 35 -14z"/>
<path d="M5060 6558 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M5714 6543 c3 -16 8 -38 12 -50 5 -19 2 -23 -14 -23 -12 0 -24 5 -27
10 -4 6 -14 10 -23 9 -34 -3 -48 2 -76 26 -56 48 -50 25 8 -30 44 -42 54 -56
42 -61 -25 -9 -75 3 -144 36 -87 41 -90 40 -94 -22 l-3 -53 -47 -3 c-39 -3
-48 -7 -48 -22 0 -10 -4 -22 -10 -25 -11 -7 -150 35 -150 46 0 4 35 13 78 20
56 10 77 18 77 29 0 9 -11 16 -28 18 -23 3 -26 6 -16 18 6 8 21 15 33 15 14 1
16 3 5 6 -9 2 -38 -8 -66 -22 -56 -28 -103 -33 -103 -11 0 8 12 17 26 20 19 5
24 10 17 17 -8 8 -18 7 -34 -2 -13 -7 -51 -14 -84 -14 l-60 -1 -12 -50 c-7
-27 -29 -116 -49 -197 -42 -168 -42 -169 36 -165 42 3 45 5 42 30 -4 35 10 42
53 24 43 -18 45 -32 5 -41 -16 -4 -30 -11 -30 -16 0 -5 2 -9 4 -9 2 0 37 -8
78 -19 111 -27 144 -46 162 -92 11 -27 24 -41 42 -45 33 -8 72 10 83 39 11 30
95 63 195 78 39 6 89 15 111 21 l40 10 -35 18 -35 19 37 15 c67 29 85 21 80
-37 -3 -29 74 -49 107 -28 11 7 4 44 -34 197 -26 104 -49 197 -51 207 -3 10
-15 17 -28 17 -31 0 -42 16 -50 73 -4 26 -12 47 -18 47 -6 0 -8 -11 -4 -27z
m-178 -133 c16 -5 48 -14 72 -20 23 -6 42 -14 42 -19 0 -11 -145 -35 -159 -27
-12 8 -15 59 -4 69 8 9 15 9 49 -3z m-324 -112 c37 -12 68 -25 68 -30 0 -4
-19 -12 -41 -18 -38 -10 -43 -9 -66 12 -27 25 -48 58 -37 58 4 0 38 -10 76
-22z m383 -34 c-21 -15 -37 -15 -36 1 0 10 42 25 51 18 3 -3 -4 -11 -15 -19z
m-521 -41 c-38 -27 -60 -10 -44 33 l11 27 25 -24 c23 -22 24 -24 8 -36z m680
45 c3 -7 7 -26 11 -42 6 -28 6 -29 -31 -23 -56 9 -64 24 -29 53 34 27 44 29
49 12z m-464 -84 c0 -28 -17 -31 -36 -6 -18 23 -17 30 4 35 25 5 32 -1 32 -29z
m230 26 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m162 -42 c39 -15 27 -24 -47 -37 -83 -15 -98 -6 -54 30 34 29 44 29 101
7z m-527 -8 c15 -16 15 -20 3 -20 -25 0 -78 21 -78 31 0 17 57 9 75 -11z"/>
<path d="M2570 6308 c0 -7 -1 -422 -1 -922 0 -808 2 -911 16 -932 l15 -24 735
0 c431 0 736 4 739 9 4 5 -24 38 -61 73 -37 34 -104 98 -148 140 l-80 76 -435
4 c-432 3 -435 3 -471 26 -71 43 -69 28 -69 632 l0 542 -58 96 c-32 54 -85
141 -117 195 -32 53 -59 97 -61 97 -2 0 -4 -6 -4 -12z"/>
<path d="M2052 4192 c6 -84 54 -187 114 -248 50 -51 144 -107 205 -122 35 -9
372 -12 1274 -12 676 0 1229 1 1231 2 1 2 -25 24 -59 50 -34 25 -165 125 -292
222 l-230 175 -1124 1 -1124 0 5 -68z"/>
<path d="M5835 4250 c-4 -6 52 -39 132 -76 76 -37 211 -101 300 -144 89 -43
238 -110 331 -149 l168 -71 750 0 c823 0 807 -1 910 61 73 45 126 102 162 174
29 59 52 142 52 190 l0 25 -1399 0 c-941 0 -1402 -3 -1406 -10z"/>
<path d="M1736 3654 c-14 -13 -16 -62 -16 -383 0 -401 1 -410 53 -397 l27 6 0
280 c0 182 4 280 10 280 6 0 21 -24 34 -52 25 -57 92 -200 133 -285 13 -28 31
-54 38 -57 14 -5 65 29 65 44 0 5 24 59 54 122 29 62 65 139 80 171 15 31 32
57 37 57 5 0 9 -115 9 -280 l0 -280 26 -6 c15 -4 30 -2 37 5 17 16 23 742 7
772 -8 14 -19 19 -38 17 -22 -2 -33 -14 -54 -58 -31 -62 -118 -247 -168 -357
-18 -40 -36 -73 -40 -73 -3 0 -21 33 -40 73 -120 261 -183 394 -192 405 -14
17 -43 15 -62 -4z"/>
<path d="M2510 3660 c-13 -8 -15 -53 -15 -307 0 -292 1 -299 23 -345 45 -89
101 -128 198 -136 76 -6 125 10 177 57 78 70 82 88 85 413 2 196 0 293 -8 307
-12 23 -42 28 -58 9 -6 -8 -12 -124 -14 -303 -4 -319 -5 -325 -68 -379 -26
-21 -42 -26 -85 -26 -72 0 -117 23 -146 74 -23 40 -24 47 -27 331 -2 183 -7
294 -14 302 -12 15 -27 16 -48 3z"/>
<path d="M3244 3646 c-55 -26 -82 -57 -111 -127 -15 -38 -18 -74 -18 -254 0
-193 2 -214 22 -256 42 -91 96 -129 198 -136 91 -7 174 16 210 59 24 29 25 34
25 188 0 196 4 190 -120 190 -80 0 -89 -2 -95 -20 -14 -45 1 -59 73 -62 l67
-3 1 -114 c1 -131 -5 -144 -76 -162 -69 -18 -137 -4 -176 35 -46 46 -57 111
-52 320 3 146 6 177 23 210 27 54 61 76 128 83 48 5 64 2 112 -21 30 -15 63
-26 72 -24 27 5 28 37 3 63 -54 57 -197 73 -286 31z"/>
<path d="M3907 3656 c-8 -8 -31 -66 -51 -128 -69 -213 -95 -293 -126 -398 -18
-58 -38 -124 -46 -148 -17 -51 -18 -98 -3 -107 5 -4 20 -4 33 -1 18 4 27 22
49 96 l27 90 144 0 144 0 29 -87 c31 -92 51 -116 81 -97 23 15 20 52 -11 149
-14 44 -59 190 -101 325 -42 135 -84 261 -94 280 -19 39 -51 50 -75 26z m92
-323 c28 -95 51 -177 51 -183 0 -6 -42 -10 -115 -10 -63 0 -115 3 -115 6 0 9
32 117 77 263 19 62 38 109 43 105 4 -5 30 -86 59 -181z"/>
<path d="M4332 3658 c-9 -9 -12 -110 -12 -395 l0 -383 25 -6 c54 -13 55 -6 55
292 0 151 4 274 8 274 5 0 21 -28 37 -62 48 -107 135 -292 144 -308 4 -8 21
-15 37 -15 33 0 40 12 149 253 33 72 63 132 68 132 4 0 7 -121 7 -268 0 -295
3 -311 54 -298 l26 6 -2 387 c-3 355 -4 387 -20 397 -39 24 -46 13 -191 -297
-45 -99 -87 -179 -91 -179 -5 1 -35 59 -68 129 -129 276 -159 338 -171 345
-18 11 -41 10 -55 -4z"/>
<path d="M5288 3656 c-14 -13 -29 -58 -140 -411 -27 -88 -61 -194 -74 -235
-28 -88 -30 -125 -8 -134 43 -16 60 10 94 142 l11 43 147 -3 146 -3 26 -82
c29 -95 39 -108 74 -99 20 5 26 13 26 34 -1 40 -226 743 -242 753 -20 13 -45
11 -60 -5z m72 -256 c17 -58 42 -137 55 -177 13 -39 21 -74 17 -77 -3 -3 -57
-6 -120 -6 -87 0 -113 3 -110 13 3 6 29 92 58 189 30 98 57 175 61 170 3 -4
21 -54 39 -112z"/>
<path d="M3855 2810 c-107 -8 -255 -27 -263 -35 -2 -2 0 -27 5 -57 9 -58 3
-55 97 -47 l38 4 3 -309 c2 -248 5 -310 16 -317 8 -5 38 -9 67 -9 l52 0 0 319
c0 208 4 322 10 326 6 4 35 9 65 12 54 6 55 6 54 37 -1 42 -12 79 -24 81 -6 1
-60 -1 -120 -5z"/>
<path d="M5583 2788 c-80 -26 -150 -135 -183 -285 -34 -152 -24 -295 26 -374
53 -85 129 -119 230 -104 38 5 56 16 93 53 46 47 91 141 91 190 0 20 -5 22
-65 22 -51 0 -65 -3 -65 -15 0 -28 -43 -103 -67 -116 -55 -29 -115 30 -127
125 -13 111 37 325 85 367 27 23 31 24 59 1 19 -15 24 -31 28 -96 3 -44 10
-79 16 -81 6 -2 35 -6 64 -9 l52 -5 0 60 c0 88 -26 190 -57 223 -47 51 -110
66 -180 44z"/>
<path d="M6103 2793 c-12 -2 -44 -24 -71 -48 -142 -128 -176 -452 -66 -627 48
-76 148 -115 226 -89 49 16 115 86 148 156 38 84 54 179 47 294 -12 226 -121
346 -284 314z m85 -129 c55 -38 80 -164 61 -308 -23 -183 -95 -266 -162 -186
-37 43 -50 119 -45 251 5 126 29 203 76 239 30 24 41 25 70 4z"/>
<path d="M4557 2699 c-9 -118 -9 -328 0 -502 l6 -135 51 -4 c28 -3 102 2 165
11 l113 16 -6 54 c-4 30 -9 57 -11 59 -2 3 -42 0 -89 -5 -46 -5 -89 -7 -94 -5
-13 4 -15 332 -3 477 4 55 5 103 2 106 -3 4 -33 10 -67 13 l-61 7 -6 -92z"/>
<path d="M6866 2778 c-2 -7 -18 -74 -36 -148 -36 -157 -61 -240 -71 -240 -3 0
-17 53 -29 118 -28 143 -47 224 -57 233 -5 4 -49 10 -100 14 l-91 6 -9 -68
c-9 -78 -7 -632 2 -642 4 -3 34 -6 67 -6 l61 0 -2 70 c-1 39 -6 165 -10 280
-5 116 -5 214 -1 219 10 10 13 0 44 -154 38 -182 72 -329 78 -335 3 -3 25 -7
48 -8 l42 -2 34 120 c19 66 46 172 60 235 14 63 30 119 35 125 11 11 11 -276
0 -430 -5 -60 -5 -113 -2 -117 4 -5 34 -11 67 -14 l61 -7 6 54 c9 70 9 480 0
598 -7 104 3 96 -121 105 -54 5 -74 3 -76 -6z"/>
<path d="M4160 2768 c-35 -5 -67 -11 -69 -14 -3 -3 -7 -162 -9 -354 l-4 -348
57 -7 c77 -10 242 0 283 17 34 13 34 13 27 63 -3 28 -9 53 -13 56 -4 4 -43 1
-87 -7 -44 -7 -92 -13 -107 -13 l-28 -1 0 89 c0 51 4 93 10 96 6 4 54 9 107
12 l95 6 -6 56 c-4 30 -9 57 -11 59 -2 3 -43 0 -90 -5 -47 -5 -88 -7 -90 -4
-3 2 -5 43 -5 91 l0 87 38 7 c20 3 70 6 110 6 l72 0 0 60 0 60 -107 -1 c-60
-1 -137 -6 -173 -11z"/>
<path d="M5041 2769 c-35 -4 -66 -10 -68 -13 -10 -10 -26 -516 -19 -608 l8
-97 55 -7 c87 -10 243 0 280 20 31 16 32 18 26 64 -3 26 -9 51 -13 55 -4 4
-27 4 -51 -2 -24 -5 -72 -12 -106 -16 l-63 -7 0 95 0 95 78 7 c42 3 88 7 102
8 24 2 25 5 22 52 -2 28 -7 54 -12 59 -5 5 -43 5 -92 -1 -46 -6 -86 -8 -90 -6
-13 7 -9 171 4 179 6 4 58 10 115 12 l103 5 0 58 0 59 -107 -1 c-60 -1 -137
-5 -172 -10z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
